// Use this version of `SitecoreContext` instead of the one that ships with `@sitecore-jss/sitecore-jss-react`.
// This one does things a bit differently and removes the need for `forceUpdate`.

import React, { useContext, useEffect } from 'react';
import {
  ComponentFactoryReactContext,
  SitecoreContextReactContext,
} from '@sitecore-jss/sitecore-jss-react/dist/components/SitecoreContext';
import { __RouterContext } from 'react-router';

import SitecoreContextFactory from './SitecoreContextFactory';
import { AppStateContext } from './AppStateProvider';
import {
  resolveItemRoute,
  resolveRouterRoute,
  resolveRouteLanguage,
} from './routerUtils';

export { ComponentFactoryReactContext, SitecoreContextReactContext };

export function SitecoreContext({ componentFactory, children }) {
  const { appState } = useContext(AppStateContext);

  const contextData = {
    route: null,
    itemId: null,
  };

  const routeData = appState.routeData;
  if (routeData && routeData.sitecore) {
    contextData.route = routeData.sitecore.route;
    contextData.itemId =
      routeData.sitecore.route && routeData.sitecore.route.itemId;
    Object.assign(contextData, routeData.sitecore.context);
  }

  SitecoreContextFactory.setSitecoreContext(contextData);

  return (
    <RouteDataManager>
      <LanguageManager>
        <ComponentFactoryReactContext.Provider value={componentFactory}>
          <SitecoreContextReactContext.Provider value={SitecoreContextFactory}>
            {children}
          </SitecoreContextReactContext.Provider>
        </ComponentFactoryReactContext.Provider>
      </LanguageManager>
    </RouteDataManager>
  );
}

// Component responsible for fetching route data if needed.
// This could probably be converted to a hook.
function RouteDataManager({ children }) {
  const { appState, actions } = useContext(AppStateContext);

  const routerContext = useContext(__RouterContext);
  const route = resolveRouterRoute(routerContext, appState);
  const routeParams = (route && route.params) || {};

  // routeParams take precedence over state
  // sitecoreRoute will only be populated if there is a value after `/` in the requested URL
  // appState.routePath is populated by server.js and index.js before initial render
  // appState.routePath is updated during route fetching

  // `sitecoreRoute` may be undefined when the current URL is `/` or
  // when the current URL starts with a language parameter, `/en/`
  // If the former, we need to "resolve" to the `/` path to ensure that
  // the `useEffect` below is triggered and route data is fetched.
  let resolvedRoute;
  if (
    !routeParams.sitecoreRoute &&
    (route.url === '/' || route.url === `/${routeParams.lang}/`)
  ) {
    resolvedRoute = '/';
  } else {
    resolvedRoute = routeParams.sitecoreRoute || appState.routePath;
  }

  const itemPath = resolveItemRoute(resolvedRoute, appState.routeMap);
  const resolvedLanguage =
    resolveRouteLanguage(resolvedRoute, appState.routeMap) || appState.language;

  // remember that useEffect fires after initial render (no matter what)
  // and then fires on subsequent renders if either resolvedRoute or resolvedLanguage has changed
  useEffect(
    () => {
      // If we have route data from SSR, no need to fetch.
      // At this point, we also clear the SSR flag from state so that subsequent renders
      // will fetch routeData if needed.
      // This approach feels hacky... but it works.
      if (appState.routeData && appState.routeData.fromSSR) {
        actions.clearSSRFlag();
        return;
      }
      actions.fetchRoute(resolvedRoute, resolvedLanguage, itemPath);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [resolvedRoute, resolvedLanguage]
  );

  return children;
}

// Component responsible for initializing language state if needed.
// This could probably be converted to a hook.
function LanguageManager({ children }) {
  const { appState, actions } = useContext(AppStateContext);
  const routerContext = useContext(__RouterContext);
  const route = resolveRouterRoute(routerContext, appState);
  const routeParams = (route && route.params) || {};

  // routeParams take precedence over state
  const resolvedLanguage = routeParams.lang || appState.language;

  // remember that useEffect fires on initial render (no matter what)
  // and then fires on subsequent renders if either resolvedRoute or resolvedLanguage has changed
  useEffect(
    () => {
      // bail if rendering on server, i18init is initialized before SSR starts
      if (typeof window === 'undefined' || !window.document) {
        return;
      }

      // also need to handle route change
      actions.initializeLanguage(resolvedLanguage);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [resolvedLanguage]
  );

  return children;
}
