import React from 'react';
import { Redirect } from 'react-router-dom';
import { Content } from './Content';

export function LayoutBodyFactory({ appState }) {
  const {
    routeData,
    routeDataLoading,
    routeError,
    languageLoading,
    languageError,
  } = appState;

  if (routeDataLoading || languageLoading) {
    // This indicates that the application is waiting for route data to be returned from LayoutService
    // and can be handled appropriately from UX standpoint
    // returning null will not show anything during this state
    // TODO: this is kept here for troubleshooting purposes and needs to be changed for production
    // return <Loading text="Loading" />;
    return null;
  }

  if (routeError) {
    // no route data for the current route in Sitecore - show not found component.
    // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
    // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
    return <Redirect to={`${appState.redirectUrl}`} />;
  }
  if (routeData && routeData.sitecore && routeData.sitecore.route === null) {
    return <Redirect to="/404" />;
  } else if (routeData && routeData.sitecore && routeData.sitecore.route) {
    return (
      <Content
        route={routeData.sitecore.route}
        context={routeData.sitecore.context}
      />
    );
  }

  return <Redirect to="/500" />;
}
